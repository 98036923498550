var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: { title: _vm.title, passedActions: _vm.primaryTitleBarActions },
      }),
      _c(
        "v-container",
        { staticClass: "fill-height pa-3", attrs: { fluid: "" } },
        [
          _vm.upsellAddonItem &&
          _vm.upsellPositions &&
          !_vm.upsellAddonItem.exclusionary
            ? _c(
                "v-row",
                [
                  _vm._l(4, function (i) {
                    return [
                      _c("EditCard", {
                        key: i,
                        attrs: {
                          position: i,
                          items: _vm.itemsForPosition(i),
                          target: _vm.upsellAddonItem.targetName,
                          selectMoreItemsText: _vm.selectMoreItemsText,
                          emptyText: _vm.emptyText,
                        },
                        on: { onChange: _vm.handleChange },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _vm.upsellAddonItem && _vm.upsellAddonItem.exclusionary
            ? _c(
                "v-row",
                [
                  [
                    _c("EditCard", {
                      key: "1",
                      attrs: {
                        position: 0,
                        exclusionary: true,
                        items: _vm.itemsForPosition(0),
                        target: _vm.upsellAddonItem.targetName,
                        selectMoreItemsText:
                          _vm.selectMoreExclusionaryItemsText,
                        emptyText: _vm.emptyExclusionaryText,
                      },
                      on: { onChange: _vm.handleChange },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.navigationConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Unsaved Changes",
                    onExit: () => (_vm.navigationConfirmationModal = false),
                    actions: _vm.navigationConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "You have unsaved changes, would you like to save these changes?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }